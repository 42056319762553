@import '@/scss/_utilities';

.root {
  width: 100%;
  touch-action: none;
}

.item {
  will-change: transform;
}
