@import "@/scss/_utilities";

.root {
  background-color: var(--color-image-placeholder);
  position: fixed;
  width: var(--vw);
  height: var(--vh);
}

.contentToggle {
  position: relative;
  z-index: 10000;
}

.textBlocks {
  position: fixed;
  z-index: 10000;
  color: var(--color-white);
  line-height: 1.4;
  margin: var(--standard-inset);
  pointer-events: none;
  .brand {
    width: calc(100% - (var(--standard-gutter) * 2));
    margin: 0 var(--standard-gutter);
    font-weight: 700;
  }
  .intro,
  .contact {
    margin: var(--standard-gutter);
    max-width: 35em;
  }
  .outro {
    margin: var(--standard-gutter);
    max-width: 30em;
  }
  a {
    pointer-events: all;
  }
  @include _sm {
    top: 0;
    left: 0;
    width: calc(var(--vw) * 0.85);
    .brand {
      font-size: rem(50px);
    }
    .intro,
    .contact,
    .outro {
      margin-bottom: calc(var(--standard-gutter) * 6);
    }
  }
  @include md_ {
    display: flex;
    flex-wrap: wrap;
    bottom: 0;
    left: 0;
    font-size: rem(12px);
    width: var(--vw);
    .brand {
      font-size: rem(80px);
    }
  }
}

.imageBlocks {
  width: var(--vw);
  height: var(--vh);
  display: flex;
  position: fixed;
  z-index: 100;
  opacity: 0.8;
  .block {
    // width: 100%;
    // height: 100%;
    width: var(--vw);
    height: var(--vh);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    .left {
      width: 50%;
    }
    .right {
      width: 50%;
    }
    .image {
      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
      }
    }
  }
}
