@import '@/scss/_utilities';

.root {
  &:not(.enabled) {
    &.loaded {
      .target {
        opacity: 1;
      }
    }
  }
  &.enabled {
    .target {
      opacity: 0;
      pointer-events: none;
      position: relative;
      z-index: 1;
      &::before {
        content: '';
        background-color: var(--color-primary);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
      }
    }
    &.open {
      .toggle {
        background-image: none;
      }
      .target {
        opacity: 1;
        pointer-events: all;
      }
    }
    &.loaded {
      .target {
        transition: 600ms opacity;
      }
    }
  }
}

.toggle {
  z-index: 10000;
  color: var(--color-white);
  line-height: 1.4;
  margin: calc(var(--standard-inset) + var(--standard-gutter));
  position: fixed;
  bottom: 0;
  left: 0;
  @include default-anchor-style;
}

.target {
  will-change: opacity;
  opacity: 0;
}
