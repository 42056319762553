@import '@/scss/_utilities';

.root {
  position: relative;
  overflow: hidden;
}

.slider {
  &::after {
    // enable flickity by default
    content: 'flickity';
    display: none;
  }
}