@import '@/scss/_utilities';

.root {
  &:is(h1, h2, h3, h4, h5, h6, p, ul, ol) {
    margin-bottom: 1rem;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
}

.body {
  a {
    @include default-anchor-style;
  }
}

.bodyBig {
  // font-size: rem(18px);
  // line-height: 1.2em;
  a {
    @include default-anchor-style;
  }
}

// .bodySmall {
//   font-size: rem(14px);
//   line-height: 1.2em;
// }

.title {
  // font-family: var(--font-serif);
  // font-size: rem(50px);
  // font-weight: 500;
  // text-transform: uppercase;
  a {
    @include default-anchor-style;
  }
}

.titleSimple {
  // font-size: rem(30px);
  // line-height: 1em;
  a {
    @include inverse-anchor-style($weight: 0.08em);
  }
}
