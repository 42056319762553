.imageWrapper {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  will-change: filter, transform;
  /* property name | duration | easing function | delay */
  transition:
    filter 350ms ease-out 100ms,
    transform 350ms ease-in;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-image-placeholder);
    opacity: 1;
    will-change: opacity;
    transition: 600ms opacity 600ms;
    pointer-events: none;
  }
  &.loaded {
    &::after {
      opacity: 0;
    }
    .image {
      user-select: none;
      display: block;
    }
  }
}

body {
  &.imageDull {
    .imageWrapper.loaded {
      filter: grayscale(1) opacity(0.3);
    }
  }
}
